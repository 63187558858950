import React, { useEffect } from "react"

import * as vendor from "css-vendor"

const generateStyleSheet = ({ imagesCount, duration, transition }) => {
	const t = imagesCount * (duration + transition)
	const p1 = Math.round((transition / t) * 100)
	const p2 = Math.round(((duration + transition) / t) * 100)
	const p3 = Math.round(p2 * 1.1)

	const vendorBackfaceVisibility = vendor.supportedProperty("backface-visibility")
	const vendorAnimation = vendor.supportedProperty("animation")
	const vendorKeyframes = vendor.supportedKeyframes("@keyframes")

	let animation = ""
	let keyframes = ""
	if (vendorAnimation && vendorBackfaceVisibility && vendorKeyframes) {
		animation = `${vendorBackfaceVisibility}: hidden;
        ${vendorAnimation}: imageAnimation ${t}s linear infinite -0.5s;`

		keyframes = `${vendorKeyframes} imageAnimation {
          0% {
            opacity: 0;
            animation-timing-function: ease-in;
          }
          ${p1}% {
            opacity: 1;
            animation-timing-function: ease-out;
          }
          ${p2}% {
            opacity: 1;
          }
          ${p3}% {
            opacity: 0
          }
          100% {
            opacity: 0
          }
        }`
	}

	return `#ReactBackgroundSlider > figure {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        color: transparent;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        opacity: 0;
        margin: 0;
        ${animation}
      }
      ${keyframes}
    `
}

const NAME = "ReactBackgroundSlider"

const injectCss = (css, meta) => {
	const head = document.getElementsByTagName("head")[0]
	const style = document.createElement("style")
	style.setAttribute("type", "text/css")
	style.setAttribute("data-meta", meta)
	if (style.styleSheet) {
		style.styleSheet.cssText = css
	} else {
		style.appendChild(document.createTextNode(css))
	}
	head.appendChild(style)
}

export default function RotateImage({ images, duration, transition }) {
	useEffect(() => {
		injectCss(
			generateStyleSheet({
				imagesCount: images.length,
				duration: duration,
				transition: transition,
			}),
			NAME
		)
	})

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				position: "relative",
			}}
		>
			<div id={NAME}>
				{images.map((img, key) => (
					<figure
						key={key}
						style={{
							backgroundImage: `url(${img.img})`,
							animationDelay: `${(duration + transition) * key}s`,
						}}
					>
						<figcaption
							style={{
								position: "absolute",
								bottom: 20,
								right: 0,
							}}
						>
							<div className={"arrow-left"}></div>
							<div
								style={{
									//color: "black",
									//background: "#eaeaea",
									//fontSize: "1.3em",
									//fontWeight: 600,
									color: "white",
									fontSize: "2em",
									//transform: "rotate(-45deg)",
									padding: "5px 50px",
								}}
							>
								{img.title}
							</div>
						</figcaption>
					</figure>
				))}
			</div>
		</div>
	)
}
