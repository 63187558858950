import React, { useEffect, useState } from "react"

import Img from "gatsby-image"

const mainBoxStyle = {
	position: "relative",
	margin: "20px",
	border: "1px solid black",
}

const lgBoxStyle = {
	...mainBoxStyle,
	width: "calc(25% - 40px)",
}

const mdBoxStyle = {
	...mainBoxStyle,
	width: "calc(50% - 40px)",
}

const smBoxStyle = {
	...mainBoxStyle,
	width: "calc(100% - 40px)",
}

const offsetImageTitleStyle = {
	height: "100%",
	top: 0,
	marginTop: "110%",
	position: "absolute",
	width: "100%",
	color: "white",
	textAlign: "center",
	fontSize: "1.3em",
	transition: "margin 150ms ease-in",
}

const hoverTextStyle = {
	height: "85%",
	bottom: 0,
	position: "absolute",
	color: "rgb(52, 52, 52)",
	padding: 10,
	fontSize: "0.8em",
	background: "rgb(255 255 255 / 95%)",
	transition: "opacity 150ms ease-in",
	opacity: 1,
	width: "100%",
	marginTop: "5%",
}

export default function HoverImage({ fluidImg, text, hoverInvert, list, idx, onHoverFn }) {
	const [boxStyle, setBoxStyle] = useState(lgBoxStyle)
	const [hover, setHover] = useState(false)

	const onHover = () => {
		onHoverFn()
		//setHover(true)
	}

	const setWidth = width => {
		if (width > 1200) {
			setBoxStyle(lgBoxStyle)
			return
		}

		if (width > 800) {
			setBoxStyle(mdBoxStyle)
			return
		}

		setBoxStyle(smBoxStyle)
	}

	useEffect(() => {
		setWidth(window.innerWidth)
		window.addEventListener("resize", () => {
			setWidth(window.innerWidth)
		})
		return () => {
			window.removeEventListener("resize", () => {})
		}
	}, [])

	let currentStyle = {
		...offsetImageTitleStyle,
	}
	if (hover) {
		currentStyle.marginTop = 5
	}

	if (hover && hoverInvert) {
		currentStyle.color = "black"
	}

	return (
		<div style={boxStyle} className={"hover-image"} onClick={onHoverFn}>
			<Img fluid={fluidImg} />
			<div style={currentStyle}>{text}</div>
			<div
				tabindex={idx}
				role="button"
				style={{ ...hoverTextStyle, opacity: hover ? 1 : 0 }}
				onMouseOver={() => onHover(true)}
				onFocus={() => onHover(true)}
				onMouseOut={() => setHover(false)}
				onBlur={() => setHover(false)}
			></div>
		</div>
	)
}
